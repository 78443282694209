/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Card from "components/card";
import { MdPermContactCalendar } from "react-icons/md";
// import ConfirmModal from "components/modal/ConfirmModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import TooltipHorizon from "components/tooltip";
import { getCallLogsList } from "services/crm";
import { Tag, useToast } from "@chakra-ui/react";
import { formatDateAmPm, numberWithCommasAndDollar, responseHendler } from "utils/utils";
import CallDurationTotal from "components/callDurationTotal";
import { FaFlag } from "react-icons/fa";
import PlaygroundFeedBackModal from "components/modal/PlaygroundFeedBackModal";
import Datepicker from "react-tailwindcss-datepicker";
import Loader from "components/Loader/Loader";
import CallLogDetailModal from "components/modal/CallLogDetailModal";
import { useTranslation } from "react-i18next";
type RowObj = {
  answered_by: any;
  full_name: string;
  phone: string;
  email: string;
  tech: any;
  date: string;
  progress: number;
  campaignName: string;
  customerName: string;
  sentiments: string;
  callDuration: string;
  status: string;
  error: string;
  actions: any;
  is_call_transferred: any;
  is_message_sent: any;
  feedback: any;
  hash_id: any;
  is_meeting_scheduled: any;
  is_voice_note: any;
  created_at: any;
  newStatus: any;
  maximum_time_per_call: any;
  recording_url: any;
  is_email_sent: any;
  call_sentiment: any;
  callsid: any;
  call_duration_in_sec: any;
  is_whatsapp_sent: any;
  call_cost: any;
};

const CallLogs = () => {
  const toast = useToast();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [callLogList, setCallLogList] = useState([]);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [callId, setCallId] = useState("");
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [sentiment, setSentiment] = useState("all");
  const [callStatus, setCallStatus] = useState("all");
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const selectedLang =
    localStorage.getItem("language") || i18n.language || "en";
  const fetchData = useCallback(() => {
    if (dates?.startDate && dates?.endDate) {
      const params = {
        page: pagination?.pageIndex,
        perpage: pagination?.pageSize,
        status: callStatus,
        sentiment: sentiment,
        fromDate: dates?.startDate,
        toDate: dates?.endDate,
      };
      fetchCallLogsList(params);
    } else {
      const params = {
        page: pagination?.pageIndex,
        perpage: pagination?.pageSize,
        status: callStatus,
        sentiment: sentiment,
        fromDate: "",
        toDate: "",
      };
      fetchCallLogsList(params);
    }
  }, [
    pagination?.pageIndex,
    pagination?.pageSize,
    callStatus,
    sentiment,
    dates?.startDate,
    dates?.endDate,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    const params = {
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      status: callStatus,
      sentiment: sentiment,
    };
    fetchCallLogsList(params);
  }, []);
  const fetchCallLogsList = (params: any) => {
    setIsLoading(true);
    getCallLogsList(params)
      .then((response) => {
        setIsLoading(false);
        responseHendler(response);
        if (response.success) {
          if (response?.data) {
            for (let item of response?.data) {
              item.full_name = item.first_name + " " + item.last_name;
            }
            setTotalPages(response?.totalPages);
            setCallLogList(response?.data);
          } else {
            setTotalPages(0);
            setCallLogList([]);
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const handleFeedbackOpen = (id: any) => {
    setFeedbackModalOpen(true);
    setCallId(id);
  };
  const handleFeedbackClose = () => {
    setFeedbackModalOpen(false);
  };
  const timeStatus = (created_at: string, time_per_call: number) => {
    const createdAtDate = new Date(created_at);

    const calculatedTime = new Date(
      createdAtDate.getTime() + time_per_call * 60000
    );
    const currentTime = new Date();

    return currentTime.getTime() < calculatedTime.getTime();
  };

  const columns = [
    columnHelper.accessor("full_name", {
      id: "Name",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.name")}
        </p>
      ),
    }),
    columnHelper.accessor("phone", {
      id: "Phone Number",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.phone")}
        </p>
      ),
    }),
    columnHelper.accessor("callDuration", {
      id: "call Duration",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.duration")}
        </p>
      ),
    }),
    columnHelper.accessor("call_cost", {
      id: "Cost",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("cost")}
        </p>
      ),
    }),
    columnHelper.accessor("created_at", {
      id: "Called At",
      header: () => (
        <p className="flex min-w-[200px] items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.calledAt")}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "Status",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.status")}
        </p>
      ),
    }),
    columnHelper.accessor("sentiments", {
      id: "Sentiments",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.sentiments")}
        </p>
      ),
    }),

    columnHelper.accessor("actions", {
      id: "Actions",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.actions")}
        </p>
      ),
    }),
    columnHelper.accessor("feedback", {
      id: "Feedback",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.feedback")}
        </p>
      ),
    }),
  ];
  const table = useReactTable({
    data: callLogList,
    columns: columns,
    state: {
      pagination,
      columnFilters: [
        { id: "status", value: callStatus },
        { id: "sentiment", value: sentiment },
      ],
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const handleDatesChange = (newValue: any) => {
    setDates(newValue);
  };
  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleOpenModal = (rowData: any) => {
    setRowData(rowData?.original);
    setIsModalOpen(!isModalOpen);
  };
  return (
    <div>
      {isLoading && <Loader />}
      <div className="tenth-step relative mb-2">
        <div className="mt-2 flex justify-end gap-4 py-2">
          <div className="flex flex-wrap items-center justify-end gap-2 md:flex-nowrap">
            <Datepicker
              displayFormat="DD-MM-YYYY"
              placeholder={t("campaign.dateFormat")}
              inputClassName="w-full min-w-[290px] rounded border-[1px] border-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              value={dates}
              onChange={handleDatesChange}
              showShortcuts={true}
              i18n={selectedLang}
            />
            <select
              value={sentiment}
              onChange={(e) => setSentiment(e.target.value)}
              className="w-fit rounded border-[1px] border-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
            >
              <option value="all">{t("campaign.allSentiments")}</option>
              <option value={"positive"}>{t("campaign.positive")}</option>
              <option value={"negative"}>{t("campaign.negative")}</option>
              <option value={"neutral"}>{t("campaign.neutral")}</option>
            </select>
            <select
              value={callStatus}
              onChange={(e) => setCallStatus(e.target.value)}
              className="w-fit rounded border-[1px] border-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
            >
              <option value="all">{t("campaign.allCallStatus")}</option>
              <option value={"completed"}>{t("campaign.completed")}</option>
              <option value={"inProgress"}>{t("campaign.inProgress")}</option>
              <option value={"failed"}>{t("campaign.failed")}</option>
              <option value={"error"}>{t("campaign.error")}</option>
            </select>
          </div>
        </div>
      </div>

      {callLogList?.length === 0 && !isLoading ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center py-16">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPermContactCalendar />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("campaign.noCallLogs")}
            </h4>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto">
            <table className="responsive-table w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          scope="col"
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows &&
                  table.getRowModel().rows.map((op, i) => (
                    <tr key={op.index}>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.name")}
                      >
                        <p
                          onClick={() => handleOpenModal(op)}
                          className="flex cursor-pointer items-center justify-end gap-2 text-blue-700 hover:underline sm:justify-center"
                        >
                          {op?.original.full_name ? (
                            <span>{op?.original.full_name}</span>
                          ) : (
                            <span>-</span>
                          )}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.phone")}
                      >
                        <p className="flex items-center justify-end gap-2 sm:justify-center">
                          {op?.original.phone ? (
                            <span>{op?.original.phone}</span>
                          ) : (
                            <span>-</span>
                          )}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.duration")}
                      >
                        <p className="flex items-center justify-end gap-2 text-center sm:justify-center">
                          <span>
                            {op?.original.answered_by !== "robot" &&
                            op?.original?.newStatus !== "Failed" ? (
                              <CallDurationTotal
                                totalDurationInSeconds={Number(
                                  op?.original?.call_duration_in_sec || 0
                                )}
                              />
                            ) : (
                              <CallDurationTotal
                                totalDurationInSeconds={Number(0)}
                              />
                            )}
                          </span>
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("cost")}
                      >
                        <p className="flex items-center justify-end gap-2 text-center sm:justify-center">
                          <span>
                            {numberWithCommasAndDollar(Number(op?.original.call_cost) || 0)}
                          </span>
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.calledAt")}
                      >
                        <p className="flex min-w-[200px] items-center justify-end gap-2 sm:justify-center">
                          {op?.original.created_at
                            ? formatDateAmPm(op?.original.created_at)
                            : "-"}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.status")}
                      >
                        <p className="flex cursor-pointer items-center justify-end gap-2 rounded-l-full sm:justify-center">
                          <span>
                            {op?.original.answered_by !== "robot" &&
                              op?.original?.callsid &&
                              op?.original?.status === "true" &&
                              op?.original?.error === null &&
                              Number(op?.original?.call_duration_in_sec) >
                                0 && (
                                <Tag variant="solid" colorScheme="green">
                                  {t("campaign.completed")}
                                </Tag>
                              )}

                            {op?.original.answered_by !== "robot" &&
                              op?.original?.callsid &&
                              op?.original?.status === "false" &&
                              Number(op?.original?.call_duration_in_sec) ===
                                0 && (
                                <span>
                                  {timeStatus(
                                    op?.original?.created_at,
                                    Number(op?.original?.maximum_time_per_call)
                                  ) ? (
                                    <Tag variant="solid" colorScheme="blue">
                                      {t("campaign.inProgress")}
                                    </Tag>
                                  ) : (
                                    <Tag variant="solid" colorScheme="yellow">
                                      {t("campaign.failed")}
                                    </Tag>
                                  )}
                                </span>
                              )}
                            {op?.original.answered_by !== "robot" &&
                              op?.original?.callsid &&
                              op?.original?.status === "false" &&
                              Number(op?.original?.call_duration_in_sec) >
                                0 && (
                                <span>
                                  <Tag variant="solid" colorScheme="yellow">
                                    {t("campaign.failed")}
                                  </Tag>
                                </span>
                              )}
                            {op?.original.answered_by !== "robot" &&
                              !op?.original?.callsid &&
                              op?.original?.status === "false" && (
                                <span>
                                  <Tag variant="solid" colorScheme="yellow">
                                    {t("campaign.pending")}
                                  </Tag>
                                </span>
                              )}
                            {op?.original.answered_by !== "robot" &&
                              op?.original?.error !== null && (
                                <TooltipHorizon
                                  trigger={
                                    <Tag variant="solid" colorScheme="red">
                                      {t("campaign.error")}
                                    </Tag>
                                  }
                                  placement="bottom"
                                  content={op?.original?.error}
                                  extra="rounded text-[11px]"
                                />
                              )}
                            {op?.original.answered_by === "robot" && (
                              <span>
                                {!op?.original?.is_voice_note ? (
                                  <Tag variant="solid" colorScheme="yellow">
                                    {t("campaign.failed")}
                                  </Tag>
                                ) : (
                                  <Tag variant="solid">
                                    {t("campaign.voiceNoteSent")}
                                  </Tag>
                                )}
                              </span>
                            )}
                          </span>
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.sentiments")}
                      >
                        <p className="flex cursor-pointer items-center justify-end gap-2 rounded-l-full sm:justify-center">
                          {/* {receivedData?.rowData?.is_started ? op?.original.call_sentiment : ""} */}
                          {op?.original?.call_sentiment &&
                          op?.original?.answered_by !== "robot" &&
                          op?.original?.newStatus !== "Failed" ? (
                            <span>
                              {op?.original?.call_sentiment?.includes(
                                "positive"
                              ) ? (
                                <Tag variant="solid" colorScheme="green">
                                  {t("campaign.positive")}
                                </Tag>
                              ) : op?.original?.call_sentiment?.includes(
                                  "negative"
                                ) ? (
                                <Tag variant="solid" colorScheme="red">
                                  {t("campaign.negative")}
                                </Tag>
                              ) : (
                                <Tag variant="solid" colorScheme="blue">
                                  {t("campaign.neutral")}
                                </Tag>
                              )}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.actions")}
                      >
                        <div className="gap-1 text-center">
                          {op?.original?.answered_by !== "robot" &&
                          op?.original?.newStatus !== "Failed" ? (
                            <div className="flex cursor-pointer items-center justify-end gap-2 rounded-l-full sm:justify-center">
                              {op?.original?.is_call_transferred && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("campaign.callTransferred")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_message_sent && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("campaign.messageSent")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_meeting_scheduled && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("campaign.meetingScheduled")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_email_sent && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("campaign.emailSent")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_whatsapp_sent && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("campaign.wMessageSent")}
                                  </Tag>
                                </div>
                              )}
                              {!op?.original?.is_call_transferred &&
                                !op?.original?.is_message_sent &&
                                !op?.original?.is_meeting_scheduled &&
                                !op?.original?.is_whatsapp_sent &&
                                !op?.original?.is_email_sent && <span>-</span>}
                            </div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("campaign.feedback")}
                      >
                        <p className="flex items-center justify-end gap-2 sm:justify-center">
                          <button
                            onClick={() => {
                              handleFeedbackOpen(op?.original?.hash_id);
                              if (Array.isArray((window as any).dataLayer)) {
                                (window as any).dataLayer.push({
                                  event: "Feedback",
                                  buttonName: "Feedback ",
                                });
                              }
                            }}
                            className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                          >
                            <FaFlag />
                          </button>
                        </p>
                      </td>
                    </tr>
                  ))}
                {/* {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const columnHeader = cell.column.id;
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={columnHeader}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>

          <div className="mb-2 mt-2 flex flex-wrap items-center justify-start gap-2">
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.setPageIndex(0)}
              disabled={pagination?.pageIndex === 0}
            >
              {"<<"}
            </button>
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            <div className="flex items-center gap-2">
              <span>
                {t("page")} {table.getState().pagination.pageIndex + 1}{" "}
                {t("of")} {totalPages}
              </span>
              <input
                type="number"
                min="1"
                max={totalPages}
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => table.setPageIndex(Number(e.target.value) - 1)}
                className="w-[60px] rounded border border-gray-300 bg-gray-50 pl-2 text-base font-medium text-gray-700 dark:bg-navy-900 dark:text-white"
              />
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </button>
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.setPageIndex(totalPages - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </button>
            </div>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <PlaygroundFeedBackModal
            onClose={handleFeedbackClose}
            isOpen={feedbackModalOpen}
            call_type={"campaign"}
            call_id={callId}
          />
          <CallLogDetailModal
            isOpen={isModalOpen}
            handleClose={handleCloseModal}
            rowData={rowData}
            timeStatus={timeStatus}
          />
        </Card>
      )}
    </div>
  );
};

export default CallLogs;
const columnHelper = createColumnHelper<RowObj>();
