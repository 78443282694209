/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import ContactCompanies from "./companies";
import ContactDeals from "./deals";
// import ContactTickets from "./tickets";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import ContactNotes from "./notes";
import EditAudianceModal from "components/modal/EditAudianceModal";
import { MdCampaign, MdEdit, MdOutlineNoteAdd } from "react-icons/md";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { formatDateAmPm, responseHendler } from "utils/utils";
import { useColorMode } from "@chakra-ui/system";
import { getActivityList } from "services/crm";
import { useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { IoAddCircle } from "react-icons/io5";
import { AiFillAudio } from "react-icons/ai";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
// import { useToast } from "@chakra-ui/react";
const ContactDetail = () => {
  const { t } = useTranslation();
  const audioUrl = process.env.REACT_APP_URL_FOR_AUDIO;
  const { colorMode } = useColorMode();
  const location = useLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("about");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );

  const contactData = location?.state;
  useEffect(() => {
    if (refresh) {
      navigate("/admin/contacts");
    }
  }, [refresh]);
  useEffect(() => {
    fetchActivityList();
  }, []);
  const fetchActivityList = () => {
    setIsLoading(true);
    getActivityList(contactData?.rowData?.contact_id)
      .then((response) => {
        setIsLoading(false);
        responseHendler(response);
        if (response.success) {
          if (response?.data) {
            setActivityList(response?.data);
          } else {
            setActivityList([]);
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    // gtm
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: tab,
        buttonName: tab,
      });
    }
  };

  const editContact = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditModalOpen(false);
  };
  const formatStringToHTML = (input: any) => {
    // Replace "- Heading:\n" with <h2> tags
    let formattedString = input
      .replace(/- (.*):\\n/g, "<h2>$1</h2>\n")
      // Replace "- Item\n" with <p> tags and add <br /> for line breaks
      .replace(/- (.*)\\n/g, "<p>$1</p>\n")
      // Replace double line breaks (\\n\\n) with <p><br/></p>
      .replace(/\\n\\n/g, "<p><br/></p>\n")
      // Replace single line breaks (\\n) with <br />
      .replace(/\\n/g, "<br />\n");

    return formattedString;
  };
  return (
    <div>
      {isLoading && <Loader />}
      <div className="mb-2">
        <div className="mt-2 flex justify-start gap-4 py-2">
          <button
            onClick={() => navigate("/admin/contacts")}
            className="flex items-center justify-center gap-2  rounded bg-blue-700 px-3 py-3 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBackText")}
          </button>
        </div>
      </div>
      <Card extra={"w-full sm:overflow-auto px-6"}>
        <div className="mb-10">
          <div className="mb-2 mt-[10px] flex justify-center pt-5">
            <div className="mb-4 w-full border-b-[2px] border-gray-200 dark:border-gray-700">
              <ul
                className="-mb-px flex flex-wrap gap-5 text-center text-sm font-medium"
                role="tablist"
              >
                <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "about"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("about")}
                    type="button"
                    role="tab"
                    aria-controls="styled-dashboard"
                    aria-selected={activeTab === "about"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                      {t("contactDetail.about")}
                    </div>
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "companies"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("companies")}
                    type="button"
                    role="tab"
                    aria-controls="styled-profile"
                    aria-selected={activeTab === "companies"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                      {t("contactDetail.companies")}
                    </div>
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "deals"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("deals")}
                    type="button"
                    role="tab"
                    aria-controls="styled-profile"
                    aria-selected={activeTab === "deals"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                      {t("contactDetail.deals")}
                    </div>
                  </button>
                </li>
                {/* <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "tickets"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("tickets")}
                    type="button"
                    role="tab"
                    aria-controls="styled-profile"
                    aria-selected={activeTab === "tickets"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                      Tickets
                    </div>
                  </button>
                </li> */}
                <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "notes"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("notes")}
                    type="button"
                    role="tab"
                    aria-controls="styled-profile"
                    aria-selected={activeTab === "notes"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                      {t("contactDetail.notes")}
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {activeTab === "about" && (
            <div>
              <div className="flex flex-col gap-5 lg:flex-row">
                <div className="flex w-full flex-col gap-1 xxl:w-[25%]">
                  <div className="flex items-center justify-end">
                    <MdEdit
                      onClick={() => {
                        editContact();
                        if (Array.isArray((window as any).dataLayer)) {
                          (window as any).dataLayer.push({
                            event: "Edit Contact",
                            buttonName: "Edit Contact",
                          });
                        }
                      }}
                      color="gray"
                      className="h-6 w-6 cursor-pointer"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">{t("contacts.jobTitle")}:</label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.job_title || "-"}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">
                      {t("contactDetail.fullName")}:
                    </label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.first_name}{" "}
                      {contactData?.rowData?.last_name}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">
                      {t("contacts.segmentName")}:
                    </label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.segment_name}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">{t("contacts.email")}:</label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.email || "-"}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">{t("contacts.phone")}:</label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.phone || "-"}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">
                      {t("contacts.lifecycleStage")}:
                    </label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.lifecycle_stage || "-"}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">
                      {t("contacts.leadStatus")}:
                    </label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.lead_status || "-"}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm">{t("contacts.syncBy")}:</label>
                    <h3 className="font-bold">
                      {contactData?.rowData?.sync_by || "-"}
                    </h3>
                  </div>
                </div>
                <div className="flex w-full flex-col gap-1 bg-lightPrimary xxl:w-[75%]">
                  {activityList && activityList.length > 0 ? (
                    <VerticalTimeline layout={"1-column-left"}>
                      {activityList &&
                        activityList.map((op, i) => (
                          <VerticalTimelineElement
                            key={i}
                            className="vertical-timeline-element--work"
                            // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            contentStyle={{
                              background:
                                colorMode === "dark" ? "#1b254b" : "#fff",
                              color: colorMode === "dark" ? "#fff" : "#000",
                            }}
                            contentArrowStyle={{
                              borderRight: `7px solid ${
                                colorMode === "dark" ? "#1b254b" : "#fff"
                              }`,
                            }}
                            date={formatDateAmPm(op?.created_date)}
                            iconStyle={{
                              background: "var(--colors-primary)",
                              color: "#000",
                            }}
                            icon={
                              op?.type === "UPDATE" ? (
                                <MdEdit color="white" />
                              ) : op?.type === "CREATE" ? (
                                <IoAddCircle color="white" />
                              ) : op?.type === "CALL_NOTES" ? (
                                <MdOutlineNoteAdd color="white" />
                              ) : op?.type === "ADD_TO_CAMPAIGN" ? (
                                <MdCampaign color="white" />
                              ) : (
                                <AiFillAudio color="white" />
                              )
                            }
                            position={"right"}
                          >
                            <div>
                              {op.type === "RECORDING" ? (
                                <div className="max-w-[300px]">
                                  <WavePlayer
                                    audio={
                                      op.notes?.startsWith("http://") ||
                                      op.notes?.startsWith("https://")
                                        ? op.notes
                                        : audioUrl + op.notes
                                    }
                                    index={i}
                                    currentPlayingIndex={currentPlayingIndex}
                                    setCurrentPlayingIndex={
                                      setCurrentPlayingIndex
                                    }
                                  />
                                </div>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: formatStringToHTML(
                                      op?.notes
                                        ? String(op.notes)
                                            .replace(/\\n/g, " ")
                                            .replace(/\n/g, " ")
                                        : ""
                                    ),
                                  }}
                                />
                              )}
                            </div>
                          </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      {t("contactDetail.noActivityFound")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {activeTab === "companies" && (
            <ContactCompanies contactData={contactData?.rowData} />
          )}
          {activeTab === "deals" && (
            <ContactDeals contactData={contactData?.rowData} />
          )}
          {/* {activeTab === "tickets" && (
            <ContactTickets contactData={contactData?.rowData} />
          )} */}
          {activeTab === "notes" && (
            <ContactNotes contactData={contactData?.rowData} />
          )}
        </div>
      </Card>

      <EditAudianceModal
        isOpen={isEditModalOpen}
        handleClose={handleCloseEdit}
        rowData={contactData?.rowData}
        setTableData={[]}
        setRefresh={setRefresh}
        refresh={refresh}
        reference="contact"
        contactData={contactData?.rowData}
      />
    </div>
  );
};

export default ContactDetail;
