/*eslint-disable*/
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex text-center">
      <div className="px-4">
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: t("termsOfService.agreement", {
                termsLink: `<a href="https://pagovision.mintlify.app/general/legal/terms_and_conditions" target="_blank" rel="noopener noreferrer" style="color: var(--colors-primary);">${t(
                  "termsOfService.termsOfService"
                )}</a>`,
                privacyLink: `<a href="https://pagovision.mintlify.app/general/legal/privacy_policy" target="_blank" rel="noopener noreferrer" style="color: var(--colors-primary);">${t(
                  "termsOfService.privacyPolicy"
                )}</a>`,
              }),
            }}
          />
        </p>
      </div>
    </div>
  );
}
