/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import ChatModal from "./ChatModal";
import { IoBulbOutline } from "react-icons/io5";
import InstructionModal from "./InstructionModal";
import { BsArrowsFullscreen } from "react-icons/bs";
import EditorModal from "./EditorModal";
import TemplatesModal from "./TemplatesModal";
import { IoFolderOutline } from "react-icons/io5";
import TooltipHorizon from "components/tooltip";
import { useColorMode } from "@chakra-ui/system";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
const modules = {
  toolbar: false,
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const ScriptModal = ({
  handleChange,
  allValues,
  status,
  value,
  id,
  disabled,
  quillCss,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false);
  const [isModalChatOpen, setIsModalChatOpen] = useState(false);
  // const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  // const [openingCall, setOpeningCall] = useState("");
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);

  // const useTemplate = () => {
  //     setIsModalOpen(!isModalOpen);
  // };
  const handleChatClose = () => {
    setIsModalChatOpen(false);
  };
  const handleInstructionClose = () => {
    setIsInstructionModalOpen(false);
  };
  useEffect(() => {
    if (selectedTemplate) {
      handleChange(selectedTemplate);
      setIsModalChatOpen(false);
    }
  }, [selectedTemplate]);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const showEditorModal = () => {
    setOpenEditorModal(true);
  };
  const closeEditorModal = () => {
    setOpenEditorModal(false);
  };
  const handleTemplateOpen = () => {
    setIsTemplateOpen(true);

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Use Templates",
        buttonName: "Use Templates",
      });
    }
  };
  const handleTemplateClose = () => {
    setIsTemplateOpen(false);
  };

  const handleTemplateSubmit = (data: any) => {
    handleChange(data, "");
    setIsTemplateOpen(false);
  };
  const openAiAssistantModal = () => {
    if (!allValues.goal) {
      toast({
        description: t("assistantForm.goalValid"),
        status: "info",
        duration: 8000,
        isClosable: true,
        position: "top-left",
      });
      return;
    }
    if (!allValues.language) {
      toast({
        description: t("assistantForm.languageValid"),
        status: "info",
        duration: 8000,
        isClosable: true,
        position: "top-left",
      });
      return;
    }
    if (status === "assistant") {
      if (!allValues.name) {
        toast({
          description: t("assistantForm.nameValid"),
          status: "info",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return;
      }
      if (!allValues.company_name) {
        toast({
          description: t("assistantForm.companyNameValid"),
          status: "info",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return;
      }
    }
    setIsModalChatOpen(true);
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Use Templates",
        buttonName: "Use Templates",
      });
    }
  };
  const quillClass =
    colorMode === "dark" ? "my-react-quill-dark" : "my-react-quill";
  return (
    <div>
      <div className="relative">
        <div className="flex w-full flex-col justify-between sm:flex-row md:flex-col lg:flex-row">
          <label className="flex items-center dark:text-white" htmlFor="Prompt">
            {t("assistantForm.script")} (
            <div
              className="flex cursor-pointer items-center gap-1 text-[18px] text-blue-700"
              onClick={() => setIsInstructionModalOpen(true)}
            >
              <TooltipHorizon
                trigger={
                  <span>
                    <IoBulbOutline />
                  </span>
                }
                placement="top"
                content={t("assistantForm.readInstructions")}
                extra="rounded text-[11px]"
              />
              <span className="hidden xxl:block">
                {t("assistantForm.readInstructions")}
              </span>
            </div>
            )
          </label>
          <div className="flex gap-2">
            <button
              onClick={() => handleTemplateOpen()}
              className="mb-2 flex items-center justify-between gap-1 rounded bg-blue-700 px-2 py-1 text-white hover:bg-blue-800 md:text-[10px] xl:text-[16px]"
              disabled={disabled}
              type="button"
            >
              <IoFolderOutline />
              {t("assistantForm.useTemplates")}
            </button>
            <button
              onClick={() => {
                openAiAssistantModal();
              }}
              className="mb-2 flex items-center justify-between gap-1 rounded bg-blue-700 px-2 py-1 text-white hover:bg-blue-800 md:text-[10px] xl:text-[16px]"
              disabled={disabled}
              type="button"
            >
              <IoBulbOutline />
              {t("assistantForm.AIAssistant")}
            </button>
          </div>
        </div>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={(value: any) => handleChange(value)}
          modules={modules}
          formats={formats}
          className={`${quillClass} train-quill propmt-quill h-full ${quillCss} w-full overflow-auto rounded bg-white text-[16px] focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:text-white`}
          id={id}
          placeholder={t("assistantForm.scriptPlaceholder")}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            border: isFocused
              ? "1px solid var(--colors-primary)"
              : "1px solid #adb5bd",
          }}
          readOnly={disabled}
        />
        <button
          className="absolute right-[10px] top-[75px] bg-[#f5f3f3] p-1 dark:bg-[#999] xxl:top-[50px]"
          onClick={showEditorModal}
        >
          <BsArrowsFullscreen />
        </button>

        <EditorModal
          onClose={closeEditorModal}
          isOpen={openEditorModal}
          submitValue={(value: any) => handleChange(value)}
          value={value}
          disabled={disabled}
          id={id}
        />
        {/* <ExistingTemplateModal
          onClose={handleClose}
          isOpen={isModalOpen}
          // useTemplate={useTemplate}
          // setSelectedFormate={setSelectedFormate}
          setSelectedTemp={setSelectedTemp}
          templates={templates}
        /> */}
        <ChatModal
          onClose={handleChatClose}
          isOpen={isModalChatOpen}
          allValues={allValues}
          status={status}
          setSelectedTemplate={setSelectedTemplate}
          // openingCall={setOpeningCall}
        />
        <InstructionModal
          onClose={handleInstructionClose}
          isOpen={isInstructionModalOpen}
        />
        <TemplatesModal
          onClose={handleTemplateClose}
          isOpen={isTemplateOpen}
          handleTemplateSubmit={handleTemplateSubmit}
        />
      </div>
    </div>
  );
};

export default ScriptModal;
