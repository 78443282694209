/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Card from "components/card";
import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  createElevenlabsConfiguration,
  deleteElevenLabConfig,
  getElevenlabs,
} from "services/integrations";
// import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "components/modal/ConfirmModal";
import { responseHendler } from "utils/utils";
const ElevenLabsComponent = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRemoveConfirm, setIsOpenRemoveConfirm] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [configData, setConfigData] = useState([]);
  //   const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    fetchElevenlabData();
  }, []);
  const validationSchema = Yup.object().shape({
    api_key: Yup.string().required(t("integrations.apiKeyValid")),
  });

  const formik = useFormik({
    initialValues: {
      api_key: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      // console.log(values);
      let payload: any = {
        api_key: values?.api_key,
      };
      setIsLoading(true);
      createElevenlabsConfiguration(payload)
        .then((response: any) => {
          setIsLoading(false);
          if (response.success) {
            fetchElevenlabData();
            resetForm();
            toast({
              description: t("messages.elevenLabConfigCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error submitting feedback:", error);
        });
    },
  });
  const fetchElevenlabData = () => {
    setIsLoading(true);
    getElevenlabs()
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          setConfigData(response?.data);
          if (response?.data && response?.data.length > 0) {
            formik.setFieldValue("api_key", response?.data[0]?.api_key);
          } else {
            formik.resetForm();
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const confirmRemove = () => {
    setIsOpenRemoveConfirm(true);
  };
  const handleCloseConfirm = () => {
    setIsOpenRemoveConfirm(false);
  };

  useEffect(() => {
    if (isConfirm === true) {
      setIsLoading(true);
      let req = {
        id: configData && configData.length > 0 ? configData[0]?.id : "",
      };
      deleteElevenLabConfig(req)
        .then((response) => {
          setIsLoading(false);
          setIsConfirm(false);
          responseHendler(response);
          if (response.success) {
            formik.resetForm();
            toast({
              description: t("messages.elevenLabsConfigDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
            fetchElevenlabData();
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);
  const goToDocs = () => {
    window.open(
      "https://pagovision.mintlify.app/general/integrations/elevenLabs",
      "_blank"
    );
  };
  return (
    <>
      <div className="mb-2">
        <div className="mt-2 flex justify-start gap-4 py-2">
          <button
            onClick={() => navigate("/admin/integrations")}
            className="flex h-[46px] items-center justify-center gap-1 rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
        </div>
      </div>

      <div className="m-auto max-w-[900px]">
        {isLoading && <Loader />}
        <Card extra={"w-full h-full p-[16px] bg-cover"}>
          <div className="mb-10 flex items-center justify-between gap-4">
            <div className="font-semibold">
              {t("integrations.generateElevenLabsConfig")}
              <span
                className="cursor-pointer pl-2 text-blue-700 hover:underline"
                onClick={() => goToDocs()}
              >
                {t("integrations.whatsAppConfig.seeDoc")}
              </span>
            </div>
            {configData && configData.length > 0 && (
              <span>
                <MdDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmRemove();
                  }}
                  className={`h-6 w-6 cursor-pointer`}
                  color="red"
                />
              </span>
            )}
          </div>
          <FormControl mt={4}>
            <FormLabel>{t("integrations.apiKey")} *</FormLabel>
            <div className="relative">
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 pr-12 text-sm leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="api_key"
                type={"text"}
                value={formik.values.api_key}
                onChange={formik.handleChange}
              />
              {/* <span
                className="absolute inset-y-0 right-0 top-2 cursor-pointer pr-4"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiFillEyeInvisible className="h-5 w-5 text-gray-400" />
                ) : (
                  <AiFillEye className="h-5 w-5 text-gray-400" />
                )}
              </span> */}
            </div>
            {formik.touched.api_key && formik.errors.api_key ? (
              <div className="text-red-500">
                {formik.errors.api_key.toString()}
              </div>
            ) : null}
          </FormControl>
          <div className="mt-4">
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
          </div>
        </Card>
        <ConfirmModal
          isOpen={isOpenRemoveConfirm}
          handleClose={handleCloseConfirm}
          setIsConfirm={setIsConfirm}
          type="ElevenLabs Config"
        />
      </div>
    </>
  );
};

export default ElevenLabsComponent;
