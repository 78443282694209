/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useColorMode,
  // FormControl,
  // FormLabel,
} from "@chakra-ui/react";
import { FaCheck, FaFemale, FaMale } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import countries from "assets/data/countries.json";
import premiumIcon from "assets/img/premium.png";
import { getLanguageKeyByValue } from "utils/utils";
import { useTranslation } from "react-i18next";
import {
  // getElevenlabs,
  getDefaultElevenlabsVoice,
  getElevenlabsVoices,
  sendElevenlabsVoice,
  // getCartesia,
  getCartesiaVoices,
} from "services/integrations";
import languages from "assets/data/language.json";
import Loader from "components/Loader/Loader";
import elevenLabs_icon from "../../assets/img/elevenlabs_icon.svg";
import elevenlabs from "../../assets/img/elevenlabs.svg";
import cartesiaImg from "../../assets/img/cartesia.svg";
import cartesiaImgWhite from "../../assets/img/cartesia-white.svg";

import Swal from "sweetalert2";
import SearchBarInput from "views/admin/assitstants/assistanceList/components/SearchbarInput";
// import { useFormik } from "formik";
// import * as Yup from "yup";
const GenderAccentModal = ({
  onClose,
  isOpen,
  voiceList,
  selectedVoiceData,
  setSelectedVoiceData,
  selectedLanguage,
  formikData,
  formData,
  apiKey11Lab,
  apiKeyCartesia,
}: any) => {
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [filterGender, setFilterGender] = useState("");
  const [filterAccent, setFilterAccent] = useState("");
  const [filterLanguage, setFilterLanguage] = useState("");
  const [filterPremium, setFilterPremium] = useState("");
  // const [apiKey, setApiKey] = useState("");
  // const [apiKeyCartesia, setApiKeyCartesia] = useState("");

  const [activeTab, setActiveTab] = useState("other");
  const [elevenLabsList, setElevenLabsList] = useState([]);
  const [cartesiaList, setCartesiaList] = useState([]);
  const [elevenGender, setElevenGender] = useState("");
  const [elevenAccent, setElevenAccent] = useState("");
  const [elevenLanguage, setElevenLanguage] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [defaultVoice, setDefaultVoice] = useState("1");
  const [searchVoiceId, setSearchVoiceId] = useState("");
  const [searchVoice, setSearchVoice] = useState("");

  const groupedVoicesByAccent = voiceList.reduce((acc: any, voice: any) => {
    acc[voice.accent] = acc[voice.accent] || [];
    acc[voice.accent].push(voice);
    return acc;
  }, {});

  // Extract group keys as accent options
  const accentOptions = Object.keys(groupedVoicesByAccent);

  const groupedLanguage = voiceList.reduce((acc: any, voice: any) => {
    acc[voice.language] = acc[voice.language] || [];
    acc[voice.language].push(voice);
    return acc;
  }, {});
  // Extract group keys as accent options
  const languageOptions = Object.keys(groupedLanguage);
  useEffect(() => {
    if (selectedVoiceData) {
      setSelectedOption(selectedVoiceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVoiceData]);
  useEffect(() => {
    if (isOpen && selectedLanguage) {
      setFilterLanguage(getLanguageKeyByValue(selectedLanguage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedLanguage]);
  useEffect(() => {
    if (isOpen) {
      if (formikData?.values?.ai_modal === "11lab" && apiKey11Lab) {
        // fetchElevenLabVoiceDetail();
        setActiveTab("elevenlabs");
        fetchDefaultElevenLabVoice(apiKey11Lab);
      }
      if (formikData?.values?.ai_modal === "cartesia" && apiKeyCartesia) {
        // fetchElevenLabVoiceDetail();
        setActiveTab("cartesia");
        fetchCartesiaVoice(apiKeyCartesia);
      }
      if (
        formikData?.values?.ai_modal !== "11lab" &&
        formikData?.values?.ai_modal !== "cartesia"
      ) {
        setActiveTab("other");
      }
      // fetchElevenLabConfig();
      // fetchCartesiaConfig();
    }
  }, [isOpen]);
  // const fetchElevenLabConfig = () => {
  //   getElevenlabs()
  //     .then((response) => {
  //       if (response.success) {
  //         // setVoiceList(voiceD);
  //         if (response?.data && response?.data.length > 0) {
  //           setApiKey(response?.data[0]?.api_key);
  //           if (formikData?.values?.ai_modal === "11lab") {
  //             fetchDefaultElevenLabVoice(response?.data[0]?.api_key);
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };
  // const fetchCartesiaConfig = () => {
  //   getCartesia()
  //     .then((response) => {
  //       if (response.success) {
  //         // setVoiceList(voiceD);
  //         if (response?.data && response?.data.length > 0) {
  //           setApiKeyCartesia(response?.data[0]?.api_key);
  //           if (formikData?.values?.ai_modal === "cartesia") {
  //             fetchCartesiaVoice(response?.data[0]?.api_key);
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };
  const fetchElevenLabVoiceList = (api: any) => {
    setIsLoading(true);
    let payload: any = {
      pagesize: 100,
      api_key: api,
    };
    if (elevenGender) {
      payload.gender = elevenGender;
    }
    if (elevenLanguage) {
      payload.language = elevenLanguage;
    }
    if (elevenAccent) {
      payload.accent = elevenAccent;
    }
    getElevenlabsVoices(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          // setVoiceList(voiceD);
          setElevenLabsList(response?.data?.voices);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const fetchDefaultElevenLabVoice = (api: any) => {
    setIsLoading(true);
    getDefaultElevenlabsVoice(api)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          // console.log(response?.data);
          setElevenLabsList(response?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const fetchCartesiaVoice = (api: any) => {
    let request = {
      api_key: api,
    };
    setIsLoading(true);
    getCartesiaVoices(request)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          // console.log(response?.data);
          setCartesiaList(response?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
    const audio = new Audio(option.voice_sample);
    audio.play();
    setAudioElement(audio);
  };
  const handleOptionSelectEleven = (option: any) => {
    setSelectedOption(option);
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
    const audio = new Audio(option.preview_url);
    audio.play();
    setAudioElement(audio);
  };
  const handleOptionSelectCartesia = (option: any) => {
    setSelectedOption(option);
  };
  const isOptionSelected = (option: any) => {
    return selectedOption && selectedOption?.id === option?.id;
  };
  const isOptionSelectedCartesia = (option: any) => {
    let voiceIdd = "";
    if (formData && formData?.cartesia_json) {
      let allCarData = JSON.parse(formData?.cartesia_json);
      if (allCarData?.voice_data && allCarData?.voice_data?.id) {
        voiceIdd = allCarData?.voice_data?.id;
      }
    }
    return selectedOption && selectedOption?.id
      ? selectedOption?.id === option?.id
      : voiceIdd === option?.id;
  };
  const isOptionSelectedEleven = (option: any) => {
    let voiceIdd = "";
    if (formData && formData?.eleven_lab_json) {
      let allElevenData = JSON.parse(formData?.eleven_lab_json);
      if (allElevenData?.voice_id) {
        voiceIdd = allElevenData?.voice_id;
      }
    }
    return selectedOption && selectedOption?.voice_id
      ? selectedOption?.voice_id === option?.voice_id
      : voiceIdd === option?.voice_id;
  };
  const handleChooseItem = () => {
    setSelectedVoiceData(selectedOption);
    formikData.setFieldValue("ai_modal", selectedOption?.ai_modal);
    setFilterGender("");
    setFilterAccent("");
    setFilterPremium("");
    onClose();
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };
  const handleChooseItemCartesia = () => {
    setSelectedVoiceData(selectedOption);
    formikData.setFieldValue("ai_modal", "cartesia");
    let cartesiaVoiceData = {
      id: selectedOption?.id,
      user_id: selectedOption?.user_id,
      is_public: selectedOption?.is_public,
      name: selectedOption?.name,
      speed: "normal",
      language: selectedOption?.language,
      emotion: ["positivity:high"],
    };
    formikData.setFieldValue("voice_data", cartesiaVoiceData);
    setFilterGender("");
    setFilterAccent("");
    setFilterPremium("");
    onClose();
  };
  const handleChooseItemEleven = () => {
    if (selectedOption) {
      sendElevenVoice(selectedOption);
      formikData.setFieldValue("ai_modal", "11lab");

      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    }
  };
  const handleCloseAccent = () => {
    setAudioElement(null);
    setFilterGender("");
    setFilterAccent("");
    setFilterPremium("");
    // formik.resetForm();
    // setIsShowCustomAdd(false);
    setElevenLanguage("");
    setElevenGender("");
    setElevenAccent("");
    setActiveTab("other");
    setSelectedOption(null);
    setElevenLabsList([]);
    setDefaultVoice("1");
    setSearchVoiceId("");
    onClose();
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };
  const handleChangeLanguage = (e: any) => {
    setFilterLanguage(e.target.value);
  };
  const handleChangeGender = (e: any) => {
    setFilterGender(e.target.value);
  };
  const handleChangeAccent = (e: any) => {
    setFilterAccent(e.target.value);
  };
  const handleChangePremium = (e: any) => {
    setFilterPremium(e.target.value);
  };
  // const handleChangeElevenlabs = (e: any) => {
  //   setIsElevenlabs(e.target.checked);
  // };
  const handleTabClick = (tab: any) => {
    if (tab === "elevenlabs") {
      fetchDefaultElevenLabVoice(apiKey11Lab);
    }
    if (tab === "cartesia") {
      fetchCartesiaVoice(apiKeyCartesia);
    }
    setActiveTab(tab);
    // gtm
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: tab,
        buttonName: tab,
      });
    }
  };
  const handleElevenLanguage = (e: any) => {
    setElevenLanguage(e.target.value);
    setIsLoading(true);
    let payload: any = {
      pagesize: 100,
      language: e.target.value,
      api_key: apiKey11Lab,
    };
    if (elevenGender) {
      payload.gender = elevenGender;
    }

    if (elevenAccent) {
      payload.accent = elevenAccent;
    }
    getElevenlabsVoices(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          // setVoiceList(voiceD);
          setElevenLabsList(response?.data?.voices);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const handleElevenGender = (e: any) => {
    setElevenGender(e.target.value);
    setIsLoading(true);
    let payload: any = {
      pagesize: 100,
      gender: e.target.value,
      api_key: apiKey11Lab,
    };
    if (elevenLanguage) {
      payload.language = elevenLanguage;
    }

    if (elevenAccent) {
      payload.accent = elevenAccent;
    }
    getElevenlabsVoices(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          // setVoiceList(voiceD);
          setElevenLabsList(response?.data?.voices);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const handleElevenAccent = (e: any) => {
    setElevenLanguage(e.target.value);
    setIsLoading(true);
    let payload: any = {
      pagesize: 100,
      accent: e.target.value,
      api_key: apiKey11Lab,
    };
    if (elevenGender) {
      payload.gender = elevenGender;
    }

    if (elevenLanguage) {
      payload.language = elevenLanguage;
    }
    getElevenlabsVoices(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          // setVoiceList(voiceD);
          setElevenLabsList(response?.data?.voices);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const handleElevenSearch = (e: any) => {
    setSearchFilter(e);
    setIsLoading(true);
    let payload: any = {
      pagesize: 100,
      api_key: apiKey11Lab,
    };
    if (e !== "") {
      payload.search = e;
    }
    if (elevenGender) {
      payload.gender = elevenGender;
    }
    if (elevenLanguage) {
      payload.language = elevenLanguage;
    }
    if (elevenAccent) {
      payload.accent = elevenAccent;
    }
    getElevenlabsVoices(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          // setVoiceList(voiceD);
          setElevenLabsList(response?.data?.voices);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const sendElevenVoice = (options: any) => {
    setIsLoading(true);
    let payload: any = {
      voice_id: options?.voice_id,
      api_key: apiKey11Lab,
      public_owner_id: options?.public_owner_id || "",
      nickname: options?.name || "",
    };
    sendElevenlabsVoice(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          setSelectedVoiceData(selectedOption);
          setElevenLanguage("");
          setElevenGender("");
          setElevenAccent("");
          formikData.setFieldValue("ai_modal", "11lab");
          formikData.setFieldValue("voice_data", response?.data);
          let elevenModal = "";
          if (response?.data) {
            if (
              response?.data?.high_quality_base_model_ids &&
              response?.data?.high_quality_base_model_ids?.length > 0
            ) {
              elevenModal = response?.data?.high_quality_base_model_ids[0];
            }
          }
          formikData.setFieldValue("eleven_lab_modal", elevenModal);
          onClose();
        } else {
          Swal.fire({
            title: "Error",
            text: response?.error?.body?.detail?.message || response?.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result: any) => {});
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  // const validationSchema = Yup.object().shape({
  //   api_key: Yup.string().required("API key is required"),
  //   voice_id: Yup.string().required("Voice id is required"),
  //   public_owner_id: Yup.string().required("Public owner id is required"),
  // });
  // const formik = useFormik({
  //   initialValues: {
  //     api_key: "",
  //     voice_id: "",
  //     public_owner_id: "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values: any, { resetForm }) => {
  //     let payload = {
  //       voice_id: values?.voice_id,
  //       api_key: values?.api_key,
  //       public_owner_id: values?.public_owner_id,
  //       nickname: "kk",
  //     };
  //     setIsLoading(true);
  //     sendElevenlabsVoice(payload)
  //       .then((response) => {
  //         setIsLoading(false);
  //         if (response.success) {
  //           setElevenLanguage("");
  //           setElevenGender("");
  //           setElevenAccent("");
  //           onClose();
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         console.error("Error:", error);
  //       });
  //   },
  // });
  const handleChangeDefaultVoice = (e: any) => {
    setDefaultVoice(e.target.value);
    if (e.target.value === "1") {
      fetchDefaultElevenLabVoice(apiKey11Lab);
    }
    if (e.target.value === "2") {
      fetchElevenLabVoiceList(apiKey11Lab);
    }
    if (e.target.value === "3") {
      setElevenLabsList([]);
    }
  };
  const handleSubmitSearchVoice = () => {
    if (searchVoiceId) {
      fetchDefaultSearch(searchVoiceId);
    }
  };
  const fetchDefaultSearch = (searchValue: any) => {
    setIsLoading(true);
    let payload: any = {
      voice_id: searchValue,
      api_key: apiKey11Lab,
      public_owner_id: "",
      nickname: "",
    };
    sendElevenlabsVoice(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          if (response?.data) {
            setElevenLabsList([response?.data]);
          }
        } else {
          Swal.fire({
            title: "Error",
            text: response?.error?.body?.detail?.message || response?.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result: any) => {});
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const filteredVoices = voiceList.filter((voice: any) => {
    if (filterAccent && voice.accent !== filterAccent) {
      return false;
    }
    if (filterGender && voice.gender !== filterGender) {
      return false;
    }
    if (filterLanguage && voice.language !== filterLanguage) {
      return false;
    }
    if (
      filterPremium &&
      voice.is_premium !== (filterPremium === "Premium" ? true : false)
    ) {
      return false;
    }
    return true;
  });
  const filteredCartesiaVoices = cartesiaList.filter((voice) =>
    voice.name.toLowerCase().includes(searchVoice.toLowerCase())
  );
  return (
    <div>
      {isLoading && <Loader />}
      <Drawer
        placement="right"
        onClose={handleCloseAccent}
        size={"xl"}
        isOpen={isOpen}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent
          maxW={"1000px"}
          borderRadius={8}
          h={"100%"}
          className="dark:bg-navy-900"
        >
          <DrawerHeader fontSize={24} textAlign={"center"}>
            {t("assistantForm.chooseGenderAccent")}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody
            // overflow={"hidden"}
            textAlign={"center"}
            padding={"0px 24px 20px 24px"}
          >
            <div className="m-auto max-w-[900px]">
              {/* <div className="text-[25px] font-bold">SMTP Integration</div> */}
              {/* <div className="my-[10px] text-[15px] font-medium text-gray-400">
        Take your journey to the next level with add-ons and integrations.
      </div> */}
              <div className="mb-2 mt-[10px] flex justify-center pt-5">
                <div className="mb-4 w-full border-b-[2px] border-gray-200 dark:border-gray-700">
                  <ul
                    className="-mb-px flex flex-wrap gap-5 text-center text-sm font-medium"
                    role="tablist"
                  >
                    <li className="me-2" role="presentation">
                      <button
                        className={`inline-block h-full p-1 ${
                          activeTab === "other"
                            ? "border-b-2 border-blue-700 text-blue-700"
                            : " text-gray-600"
                        }`}
                        onClick={() => handleTabClick("other")}
                        type="button"
                        role="tab"
                        aria-controls="styled-dashboard"
                        aria-selected={activeTab === "other"}
                      >
                        <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                          {/* {t("integrations.smtp.smtpTemplate")} */}
                          {t("DefaultVoices")}
                        </div>
                      </button>
                    </li>
                    {apiKey11Lab && (
                      <li className="me-2" role="presentation">
                        <button
                          className={`inline-block h-full p-1 ${
                            activeTab === "elevenlabs"
                              ? "border-b-2 border-blue-700 text-blue-700"
                              : " text-gray-600"
                          }`}
                          onClick={() => handleTabClick("elevenlabs")}
                          type="button"
                          role="tab"
                          aria-controls="styled-profile"
                          aria-selected={activeTab === "elevenlabs"}
                        >
                          <div className="flex items-center justify-center gap-2 px-3 text-[16px]">
                            {/* {t("integrations.smtp.smtpConfig")} */}
                            <img
                              src={
                                colorMode === "light"
                                  ? elevenLabs_icon
                                  : elevenlabs
                              }
                              alt="elevenLabs logo"
                              className="h-[32px] w-[32px]"
                            />
                            {t("ElevenLabs")}
                          </div>
                        </button>
                      </li>
                    )}
                    {apiKeyCartesia && (
                      <li className="me-2" role="presentation">
                        <button
                          className={`inline-block h-full p-1 ${
                            activeTab === "cartesia"
                              ? "border-b-2 border-blue-700 text-blue-700"
                              : " text-gray-600"
                          }`}
                          onClick={() => handleTabClick("cartesia")}
                          type="button"
                          role="tab"
                          aria-controls="styled-profile"
                          aria-selected={activeTab === "cartesia"}
                        >
                          <div className="flex items-center justify-center gap-2 px-3 text-[16px]">
                            {/* {t("integrations.smtp.smtpConfig")} */}
                            <img
                              src={
                                colorMode === "light"
                                  ? cartesiaImg
                                  : cartesiaImgWhite
                              }
                              alt="cartesia logo"
                              className="h-[24px] w-[24px]"
                            />
                            {t("cartesia")}
                          </div>
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {activeTab === "other" && (
                <div>
                  <div className="mb-2 flex gap-4">
                    <select
                      id="language"
                      value={filterLanguage}
                      onChange={handleChangeLanguage}
                      className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    >
                      <option value="">{t("assistantForm.allLanguage")}</option>
                      {languageOptions.map((language) => (
                        <option key={language} value={language}>
                          {language}
                        </option>
                      ))}
                    </select>
                    <select
                      id="gender"
                      value={filterGender}
                      onChange={handleChangeGender}
                      className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    >
                      <option value="">{t("assistantForm.allGender")}</option>
                      <option value={"Male"}>{t("assistantForm.male")}</option>
                      <option value={"Female"}>
                        {t("assistantForm.female")}
                      </option>
                    </select>
                    <select
                      id="accent"
                      value={filterAccent}
                      onChange={handleChangeAccent}
                      className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    >
                      <option value={""}>{t("assistantForm.allAccent")}</option>
                      {accentOptions.map((accent) => (
                        <option key={accent} value={accent}>
                          {countries[accent as keyof typeof countries]}
                        </option>
                      ))}
                      {/* <option value=""></option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option> */}
                    </select>
                    <select
                      id="premium"
                      value={filterPremium}
                      onChange={handleChangePremium}
                      className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    >
                      <option value={""}>{t("assistantForm.all")}</option>
                      <option value={"Premium"}>
                        {t("assistantForm.premium")}
                      </option>
                      <option value={"Normal"}>
                        {t("assistantForm.normal")}
                      </option>
                    </select>
                  </div>
                  <div className="h-full overflow-auto pb-8">
                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                      {filteredVoices &&
                        filteredVoices.map((op: any, i: any) => (
                          <div
                            key={i}
                            className="flex cursor-pointer items-center justify-between border-[1px] p-3"
                            onClick={() => handleOptionSelect(op)}
                          >
                            <div className="flex items-center gap-2">
                              <img
                                src={`${
                                  process.env.REACT_APP_URL_FOR_AUDIO
                                }flags/${op?.accent.toLowerCase()}.svg`}
                                alt="flag"
                                className="w-[40px]"
                              />
                              <span className="text-left">
                                <span className="flex items-center">
                                  <span className="font-bold">
                                    {op?.displayname}
                                  </span>{" "}
                                  <span>({op?.language})</span>
                                  {op?.is_premium && (
                                    <span>
                                      <img
                                        src={premiumIcon}
                                        className="ml-3 h-[20px] w-[20px] rounded-lg"
                                        alt="primium"
                                      />
                                    </span>
                                  )}
                                </span>
                                {/* <br /> */}
                                {op?.gender}{" "}
                              </span>
                            </div>
                            <div>
                              {isOptionSelected(op) && (
                                <FaCheck color="green" />
                              )}{" "}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "elevenlabs" && (
                <div>
                  <div className="mb-2 flex gap-4">
                    <select
                      id="defaultVoice"
                      value={defaultVoice}
                      onChange={(e) => handleChangeDefaultVoice(e)}
                      className="w-full max-w-[150px] rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    >
                      <option value="1">My Voices</option>
                      <option value="2">Voice Library</option>
                      <option value="3">Search by Voice Id</option>
                    </select>
                    {defaultVoice === "2" && (
                      <>
                        <select
                          id="language"
                          value={elevenLanguage}
                          onChange={(e) => handleElevenLanguage(e)}
                          className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                        >
                          <option value="">
                            {t("assistantForm.allLanguage")}
                          </option>
                          {Object.entries(languages).map(([language, code]) => (
                            <option key={code} value={language?.toLowerCase()}>
                              {language}
                            </option>
                          ))}
                        </select>
                        <select
                          id="gender"
                          value={elevenGender}
                          onChange={(e) => handleElevenGender(e)}
                          className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                        >
                          <option value="">
                            {t("assistantForm.allGender")}
                          </option>
                          <option value={"male"}>
                            {t("assistantForm.male")}
                          </option>
                          <option value={"female"}>
                            {t("assistantForm.female")}
                          </option>
                        </select>
                        <select
                          id="accent"
                          value={elevenAccent}
                          onChange={(e) => handleElevenAccent(e)}
                          className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                        >
                          <option value={""}>
                            {t("assistantForm.allAccent")}
                          </option>
                          <option value="american">American</option>
                          <option value="british">British</option>
                          <option value="australian">Australian</option>
                          <option value="new_zealand">New Zealand</option>
                          <option value="irish">Irish</option>
                          <option value="scottish">Scottish</option>
                          <option value="south_african">South African</option>
                          <option value="indian">Indian</option>
                        </select>
                        {/* <input
                      id="search"
                      value={searchFilter}
                      onChange={(e) => handleElevenSearch(e)}
                      className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    /> */}
                        <div className="w-full">
                          <SearchBarInput
                            initialData={searchFilter}
                            handleFilteredData={handleElevenSearch}
                          />
                        </div>
                      </>
                    )}
                    {defaultVoice === "3" && (
                      <>
                        <input
                          id="searchVoiceId"
                          value={searchVoiceId}
                          placeholder="Enter Voice Id"
                          onChange={(e) => setSearchVoiceId(e.target.value)}
                          className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                        />
                        <Button
                          onClick={handleSubmitSearchVoice}
                          colorScheme="blue"
                          mr={3}
                          isDisabled={!searchVoiceId}
                        >
                          {t("submit")}
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="h-full overflow-auto pb-8">
                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                      {/* <div className="flex items-center justify-between border-[1px] p-3">
                        <div className="flex items-center gap-2">
                          {op?.gender === "female" ? (
                            <FaFemale className="h-10 w-10 text-blue-700" />
                          ) : (
                            <FaMale className="h-10 w-10 text-blue-700" />
                          )}
                          <span className="text-left">
                            <span className="flex items-center">
                              <span className="font-bold">
                                {op?.name}{" "}
                                <span className="font-medium">
                                  ({op?.language})
                                </span>
                              </span>{" "}
                            </span>
                            <span className="capitalize">
                              {op?.use_case} ({op?.gender}){" "}
                            </span>
                          </span>
                        </div>
                        <div>
                        <FaCheck color="green" />
                        </div>
                      </div> */}
                      {elevenLabsList &&
                        elevenLabsList.map((op: any, i: any) => (
                          <div
                            key={i}
                            className="flex cursor-pointer items-center justify-between border-[1px] p-3"
                            onClick={() => handleOptionSelectEleven(op)}
                          >
                            <div className="flex items-center gap-2">
                              {op?.gender === "female" ? (
                                <FaFemale className="h-10 w-10 text-blue-700" />
                              ) : (
                                <FaMale className="h-10 w-10 text-blue-700" />
                              )}
                              {defaultVoice === "2" && (
                                <span className="text-left">
                                  <span className="flex items-center">
                                    <span className="font-bold">
                                      {op?.name}{" "}
                                      <span className="font-medium">
                                        ({op?.language})
                                      </span>
                                    </span>{" "}
                                  </span>
                                  <span className="capitalize">
                                    {op?.use_case?.replace("_", " ")} (
                                    {op?.gender}){" "}
                                  </span>
                                </span>
                              )}{" "}
                              {(defaultVoice === "1" ||
                                defaultVoice === "3") && (
                                <span className="text-left">
                                  <span className="flex items-center">
                                    <span className="font-bold">
                                      {op?.name}{" "}
                                      <span className="font-medium">
                                        ({op?.labels?.language})
                                      </span>
                                    </span>{" "}
                                  </span>
                                  <span className="capitalize">
                                    {op?.labels?.use_case?.replace("_", " ")} (
                                    {op?.labels?.gender}){" "}
                                  </span>
                                </span>
                              )}
                            </div>
                            <div>
                              {isOptionSelectedEleven(op) && (
                                <FaCheck color="green" />
                              )}{" "}
                            </div>
                          </div>
                        ))}
                    </div>
                    {/* <div>
                      <Button
                        onClick={() => setIsShowCustomAdd(true)}
                        colorScheme="blue"
                        mr={3}
                      >
                        Add Custom Accent
                      </Button>
                      {isShowCustomAdd && (
                        <div className="text-left">
                          <FormControl mt={4}>
                            <FormLabel>API Key *</FormLabel>
                            <input
                              className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                              id="api_key"
                              name="api_key"
                              value={formik.values.api_key}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.api_key && formik.errors.api_key ? (
                              <div className="text-red-500">
                                {formik.errors.api_key.toString()}
                              </div>
                            ) : null}
                          </FormControl>
                          <FormControl mt={4}>
                            <FormLabel>Voice Id *</FormLabel>
                            <input
                              className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                              id="voice_id"
                              name="voice_id"
                              value={formik.values.voice_id}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.voice_id &&
                            formik.errors.voice_id ? (
                              <div className="text-red-500">
                                {formik.errors.voice_id.toString()}
                              </div>
                            ) : null}
                          </FormControl>
                          <FormControl mt={4}>
                            <FormLabel>Public Owner Id *</FormLabel>
                            <input
                              className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                              id="public_owner_id"
                              name="public_owner_id"
                              value={formik.values.public_owner_id}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.public_owner_id &&
                            formik.errors.public_owner_id ? (
                              <div className="text-red-500">
                                {formik.errors.public_owner_id.toString()}
                              </div>
                            ) : null}
                          </FormControl>
                          <div className="mt-4 text-center">
                            <Button
                              onClick={() => formik.handleSubmit()}
                              colorScheme="blue"
                              mr={3}
                            >
                              {t("add")}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              )}
              {activeTab === "cartesia" && (
                <div>
                  <div className="align mb-4 flex items-center justify-end gap-4">
                    <>
                      <input
                        id="searchVoice"
                        value={searchVoice}
                        placeholder={t("search") + "..."}
                        onChange={(e) => setSearchVoice(e.target.value)}
                        className="w-full max-w-[200px] rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                      />
                    </>
                  </div>
                  <div className="h-full overflow-auto pb-8">
                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                      {filteredCartesiaVoices &&
                        filteredCartesiaVoices.map((op: any, i: any) => (
                          <div
                            key={i}
                            className="flex cursor-pointer items-center justify-between border-[1px] p-3"
                            onClick={() => handleOptionSelectCartesia(op)}
                          >
                            <div className="flex items-center gap-2">
                              {/* {op?.gender === "female" ? (
                                <FaFemale className="h-10 w-10 text-blue-700" />
                              ) : (
                                <FaMale className="h-10 w-10 text-blue-700" />
                              )} */}
                              <span className="text-left">
                                <span className="flex items-center">
                                  <span className="font-bold">
                                    {op?.name}{" "}
                                    <span className="font-medium">
                                      ({op?.language})
                                    </span>
                                  </span>{" "}
                                </span>
                                {/* <span className="capitalize">
                                  {op?.use_case?.replace("_", " ")} (
                                  {op?.gender}){" "}
                                </span> */}
                              </span>
                            </div>
                            <div>
                              {isOptionSelectedCartesia(op) && (
                                <FaCheck color="green" />
                              )}{" "}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </DrawerBody>
          <DrawerFooter display={"flex"} justifyContent={"start"}>
            {activeTab === "other" && (
              <Button
                onClick={handleChooseItem}
                colorScheme="blue"
                mr={3}
                isDisabled={!selectedOption}
              >
                {t("assistantForm.choose")}
              </Button>
            )}
            {activeTab === "elevenlabs" && (
              <Button
                onClick={handleChooseItemEleven}
                colorScheme="blue"
                mr={3}
                isDisabled={!selectedOption}
              >
                {t("assistantForm.choose")}
              </Button>
            )}
            {activeTab === "cartesia" && (
              <Button
                onClick={handleChooseItemCartesia}
                colorScheme="blue"
                mr={3}
                isDisabled={!selectedOption}
              >
                {t("assistantForm.choose")}
              </Button>
            )}
            <Button onClick={handleCloseAccent}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default GenderAccentModal;
