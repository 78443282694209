/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
const modules = {
  toolbar: false,
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
const EditorModal = ({
  onClose,
  isOpen,
  submitValue,
  value,
  disabled,
  id,
}: any) => {
  const { t } = useTranslation();
  //   const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [editorValue, setEditorValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setEditorValue(value);
    }
  }, [isOpen]);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleConfirm = () => {
    submitValue(editorValue);
    onClose();
  };
  const handleModalClose = () => {
    onClose();
  };
  const handleChangeValue = (value: any) => {
    setEditorValue(value);
  };
  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      size={"xl"}
      isOpen={isOpen}
      blockScrollOnMount={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent
        maxW={"900px"}
        h={"100%"}
        overflowY="auto"
        borderRadius={8}
        className="dark:bg-navy-900"
      >
        <ModalHeader fontSize={24}>
          {t("assistantForm.scriptBehave")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="h-full p-2">
            <ReactQuill
              theme="snow"
              value={editorValue}
              onChange={(value: any) => handleChangeValue(value)}
              modules={modules}
              formats={formats}
              className="train-quill propmt-quill h-full min-h-[370px] w-full overflow-auto rounded bg-white text-[16px] focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:text-white"
              id={id}
              placeholder={t("assistantForm.scriptPlaceholder")}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{
                border: isFocused
                  ? "1px solid var(--colors-primary)"
                  : "1px solid #adb5bd",
              }}
              readOnly={disabled}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={() => {
              handleConfirm();
            }}
            className={`linear flex w-fit items-center justify-center gap-2 rounded  bg-blue-700 px-[16px] py-[6px] text-base font-medium text-white transition duration-200  hover:bg-blue-800 active:bg-blue-800`}
          >
            {t("assistantForm.saveClose")}
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditorModal;
