/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  affiliateInviteUser,
  affiliateWithdrawal,
  getAffiliateWithdrawalList,
} from "services/affiliate";
import Loader from "components/Loader/Loader";
import { Tag, useToast } from "@chakra-ui/react";
import Pagination from "components/pagination";
import { formatDateAmPm, responseHendler } from "utils/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
const Affiliate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const [isRequest, setIsRequest] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTableData = tableData?.slice(indexOfFirstUser, indexOfLastUser);
  const affiliation_amount = localStorage?.getItem("affiliation_amount");
  const affiliate_percentage = localStorage?.getItem("affiliate_percentage");
  const user_id = localStorage?.getItem("user_id");
  const handleCopy = () => {
    const affiliateLink = `https://ai.pagovision.com/auth/sign-up?aff=${user_id}`;
    navigator.clipboard.writeText(affiliateLink);
    toast({
      description: t("affiliate.copyText"),
      status: "success",
      duration: 4000,
      isClosable: true,
      position: "top-left",
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAffiliateWithdrawalList()
      .then((response) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.success) {
          setTableData(response?.data);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  }, [refresh]);

  const inviteValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("affiliate.emailValid2"))
      .required(t("affiliate.emailValid")),
  });

  const inviteFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: inviteValidationSchema,
    onSubmit: (values, { resetForm }) => {
      const affiliateInviteUserPayload = {
        email: values.email,
      };
      setIsRequest(true);
      let email = values.email;
      affiliateInviteUser(affiliateInviteUserPayload)
        .then((response: any) => {
          if (response.success) {
            setIsRequest(false);
            toast({
              description: t("messages.INVITED_USER", { email }),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
          } else {
            setIsRequest(false);
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error: any) => {
          console.error("Error:", error);
          setIsRequest(false);
        });
    },
  });

  const withdrawalValidationSchema = Yup.object().shape({
    description: Yup.string().required(t("affiliate.bankInfoValid")),
    amount: Yup.number()
      .min(10, t("affiliate.amountValid1"))
      .required(t("affiliate.amountValid")),
  });

  const withdrawalFormik = useFormik({
    initialValues: {
      description: "",
      amount: "",
    },
    validationSchema: withdrawalValidationSchema,
    onSubmit: (values, { resetForm }) => {
      const affiliateWithdrawalPayload = {
        description: values?.description,
        amount: values?.amount,
      };
      setIsRequest(true);
      affiliateWithdrawal(affiliateWithdrawalPayload)
        .then((response: any) => {
          if (response.success) {
            setIsRequest(false);
            toast({
              description: t("messages.WITHDRAWAL_REQUESTED"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
            setRefresh(!refresh);
          } else {
            setIsRequest(false);
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error: any) => {
          console.error("Error:", error);
          setIsRequest(false);
        });
    },
  });

  return (
    <div>
      {isRequest && <Loader />}
      <div>
        <div className="mt-2 flex justify-end gap-4 py-2">
          <button
            onClick={() => navigate("/admin/affiliate/affiliate-users")}
            className="h-[40px] rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            {t("affiliate.affiliatedUsers")}
          </button>
        </div>
      </div>
      <Card
        extra={
          "w-full sm:overflow-auto px-6 bg-gradient-to-b dark:from-blue-700 dark:via-blue-700 from-blue-100 via-blue-100 to-white"
        }
      >
        <div className="flex flex-col justify-between p-5 md:flex-row">
          <div className="flex flex-col">
            <div className="mb-8 max-w-[500px] text-[20px] font-semibold">
              {t("affiliate.caption")}🎁
            </div>
            <span className="mb-2 font-medium">
              {t("affiliate.affiliateLink")}
            </span>
            <div className="relative w-full">
              <input
                type="text"
                id="default-search"
                className="bg-gray-50s block w-full rounded border border-gray-300 p-3 pl-5 pr-8 text-sm text-gray-900 focus:outline-none dark:border-white dark:bg-[#111C44] dark:text-white"
                placeholder={t("affiliate.affiliateLink")}
                value={`https://ai.pagovision.com/auth/sign-up?aff=${user_id}`}
                readOnly
              />
              <FaCopy
                onClick={handleCopy}
                className="absolute inset-y-0 right-1 top-[15px] mr-2 flex cursor-pointer items-center text-gray-400"
                size={15}
              />
            </div>
          </div>
          <div className="mt-[20px] flex flex-col text-center md:mt-0">
            <div className="font-semibold">{t("affiliate.earnings")}</div>
            <span className="text-[44px] font-extrabold">
              ${affiliation_amount}
            </span>
            <div className="text-gray-400 dark:text-white">
              {t("affiliate.ComissionRate")} :{" "}
              <span className="font-extrabold text-[#000000] dark:text-white">
                {affiliate_percentage}%
              </span>
            </div>
            <div className="text-gray-400 dark:text-white">
              {t("affiliate.referralProgram")} :{" "}
              <span className="font-extrabold text-[#000000] dark:text-white">
                {t("affiliate.allPurchases")}
              </span>
            </div>
          </div>
        </div>
      </Card>
      <div className="mt-[30px] flex flex-col justify-between gap-8 md:flex-row">
        <Card extra={"w-full sm:overflow-auto p-6"}>
          <form onSubmit={inviteFormik.handleSubmit}>
            <div className="flex flex-col">
              <span className="text-[20px] font-semibold">
                {t("affiliate.howWork")}
              </span>
              <div className="mt-4 flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  1
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">
                    <Trans i18nKey="affiliate.howWorkText.text1">
                      <span className="font-semibold" />
                    </Trans>
                  </h6>
                </div>
              </div>
              <div className="mt-4 flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  2
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">
                    <Trans i18nKey="affiliate.howWorkText.text2">
                      <span className="font-semibold" />
                    </Trans>
                  </h6>
                </div>
              </div>
              <div className="mt-4 flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  3
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">
                    <Trans i18nKey="affiliate.howWorkText.text3">
                      <span className="font-semibold" />
                    </Trans>
                  </h6>
                </div>
              </div>
              <span className="my-2 mt-4 font-medium">
                {t("affiliate.affiliateEmail")}
              </span>
              <div className="relative w-full">
                <input
                  type="text"
                  id="email"
                  className="inline-flex w-full rounded border border-gray-500 bg-white px-4 py-3 pr-8 text-sm font-medium focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
                  name="email"
                  placeholder={t("affiliate.emailAddress")}
                  value={inviteFormik.values.email}
                  onChange={inviteFormik.handleChange}
                />
                {inviteFormik.errors.email && inviteFormik.touched.email ? (
                  <div className="text-red-500">
                    {inviteFormik.errors.email}
                  </div>
                ) : null}
                <MdOutlineMailOutline
                  className="absolute inset-y-0 right-1 top-[14px] z-[99] mr-2 flex cursor-pointer items-center text-gray-400"
                  size={20}
                />
              </div>
              <button
                type="submit"
                className="mt-5 h-[40px] rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
              >
                {t("affiliate.howWorkText.send")}
              </button>
            </div>
          </form>
        </Card>
        <Card extra={"w-full sm:overflow-auto p-6"}>
          <form onSubmit={withdrawalFormik.handleSubmit}>
            <div className="flex flex-col">
              <span className="text-[20px] font-semibold">
                {t("affiliate.withdrawalForm")}
              </span>
              <span className="my-2 font-medium">
                {t("affiliate.bankInfo")}
              </span>
              <textarea
                id="description"
                className="inline-flex w-full rounded border border-gray-500 bg-white px-4 py-3 text-sm font-medium focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
                placeholder={t("affiliate.bankInfoPlaceholder")}
                name="description"
                value={withdrawalFormik.values.description}
                onChange={withdrawalFormik.handleChange}
              />
              {withdrawalFormik.errors.description &&
              withdrawalFormik.touched.description ? (
                <div className="text-red-500">
                  {withdrawalFormik.errors.description}
                </div>
              ) : null}
              <span className="my-2 font-medium">{t("affiliate.amount")}</span>
              <input
                type="number"
                className="inline-flex w-full rounded border border-gray-500 bg-white px-4 py-3 text-sm font-medium focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
                placeholder={t("affiliate.amountValid1")}
                name="amount"
                value={withdrawalFormik.values.amount}
                onChange={withdrawalFormik.handleChange}
              />
              {withdrawalFormik.errors.amount &&
              withdrawalFormik.touched.amount ? (
                <div className="text-red-500">
                  {withdrawalFormik.errors.amount}
                </div>
              ) : null}
              <button
                type="submit"
                className="mt-5 h-[40px] rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
              >
                {t("affiliate.sendRequest")}
              </button>
            </div>
          </form>
        </Card>
      </div>
      <div className="my-[16px] text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
        {t("affiliate.withdrawalRequests")}
      </div>
      <Card extra={"w-full sm:overflow-auto px-6"}>
        <div className="mt-1 overflow-x-auto pb-4">
          <table className="responsive-table w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.withdrawalTable.no")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.withdrawalTable.amount")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.withdrawalTable.description")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.withdrawalTable.status")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.withdrawalTable.date")}
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTableData && currentTableData?.length > 0 ? (
                currentTableData?.map((item: any, index) => (
                  <tr key={index}>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.withdrawalTable.no")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {index + 1}
                      </p>
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.withdrawalTable.amount")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {item?.amount}
                      </p>
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.withdrawalTable.description")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {item?.description}
                      </p>
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.withdrawalTable.status")}
                      align="center"
                    >
                      {item?.tran_status === "1" ? (
                        <Tag variant="solid" colorScheme="green">
                          {t("affiliate.withdrawalTable.approve")}
                        </Tag>
                      ) : item?.tran_status === "2" ? (
                        <Tag variant="solid" colorScheme="red">
                          {t("affiliate.withdrawalTable.disapprove")}
                        </Tag>
                      ) : (
                        <Tag variant="solid" colorScheme="blue">
                          {t("affiliate.withdrawalTable.pending")}
                        </Tag>
                      )}
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.withdrawalTable.date")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {formatDateAmPm(item?.created_at)}
                      </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="py-4">
                    <div className="mb-auto flex flex-col items-center justify-center">
                      <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                        {t("affiliate.withdrawalTable.noWithdrawal")}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            perPage={perPage}
            allTotal={tableData?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </Card>
      {/* )} */}
    </div>
  );
};

export default Affiliate;
